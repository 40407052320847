import store from "../store/index";
import config from "../config";

const PLAID_URL = "https://cdn.plaid.com/link/v2/stable/link-initialize.js";

export function loadScript() {
  return new Promise((resolve, reject) => {
    if (document.querySelector('script[src="' + PLAID_URL + '"]')) {
      resolve(null);
      return;
    }
    const el = document.createElement("script");
    el.type = "text/javascript";
    el.async = true;
    el.src = PLAID_URL;
    el.addEventListener("load", resolve);
    el.addEventListener("error", reject);
    el.addEventListener("abort", reject);
    document.head.appendChild(el);
  });
}

/* eslint-disable */
declare global {
  interface Window {
    linkHandler: any;
    Plaid: any;
  }
}
/* eslint-enable */

export function storePlaidToken(plaidLink: string) {
  window.linkHandler = window.Plaid.create({
    token: plaidLink,
    env: config.plaid.environment,
    onSuccess: async (public_token: string) => {
      store.commit("clients/setPlaidPublicToken", public_token);
      await store.dispatch("clients/storePlaidPublicToken");
    }
  });
  window.linkHandler.open();
}
