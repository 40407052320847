<template>
  <div class="w-max">
    <lf-card :has-header-divider="false" class="py-6">
      <template v-slot:header>
        <lf-h3 class="-mb-5">
          {{ $t("ORGANIZATION.COMMISSIONS.HEADLINE_REVENUE_SHARE") }}
        </lf-h3>
      </template>
      <template v-slot:default>
        <loader :is-loading="isSubmitting" />
        <form @submit="onSubmit">
          <div class="flex flex-col space-y-5">
            <div
              class="flex justify-between items-center w-full pl-6 pr-4 py-2 bg-disabled rounded"
            >
              <div class="flex justify-start items-center space-x-5 py-3">
                <span class="text-gray-600 font-medium">
                  {{ $t("ORGANIZATION.COMMISSIONS.REVENUE_SHARE_FROM_DEALS") }}
                </span>
              </div>
              <div v-if="canChangeRevenue" class="flex align-baseline">
                <lf-input
                  :value="revenueShare"
                  name="revenue_share"
                  class="w-20 -mb-0"
                  :small-size="true"
                  :grouped-right="true"
                  type="number"
                  step="0.1"
                />
                <span
                  class="flex items-center justify-center w-9 h-9 border rounded rounded-l-none"
                >
                  %
                </span>
              </div>
              <lf-h3 v-else>{{ revenueShare }}%</lf-h3>
            </div>
            <div
              class="flex justify-between items-center w-full h-min pl-6 pr-4 bg-disabled rounded"
            >
              <div class="flex justify-start items-center space-x-5 py-5">
                <span class="text-gray-600 font-medium">
                  {{
                    isFundingAdvisor || isClientFundingAdvisor
                      ? formW9
                        ? formW9.original_name
                        : $t("ORGANIZATION.COMMISSIONS.FORM_W9_NOT_UPLOADED")
                      : $t("ORGANIZATION.COMMISSIONS.FORM_W9")
                  }}
                </span>
              </div>
              <span
                v-if="isFundingAdvisor || isClientFundingAdvisor"
                class="text-xxs sm:text-sm font-medium cursor-pointer"
                :class="{
                  'text-primary': !(isFundingAdvisor || isClientFundingAdvisor)
                }"
                @click="handleW9Download"
              >
                {{ $t("DEALS.DOCUMENTS.DOWNLOAD") }}
              </span>
              <lf-external-link v-else href="/documents/fw9.pdf">
                {{ $t("DEALS.DOCUMENTS.DOWNLOAD") }}
              </lf-external-link>
            </div>
            <lf-file-upload
              v-if="!isFundingAdvisor"
              :upload-description="
                $t('ORGANIZATION.COMMISSIONS.UPLOAD_FORM_W9')
              "
              :current-file="formW9"
              accept=".pdf"
              @fileUploaded="uploadFile"
            />
          </div>
          <div class="mt-7">
            <lf-h3 class="mb-8">
              {{ $t("ORGANIZATION.COMMISSIONS.HEADLINE_BANK_ACCOUNT") }}
            </lf-h3>

            <div>
              <div class="flex space-x-5">
                <lf-password
                  placeholder="Account Number"
                  :value="piis.accountNumber"
                  name="account_number"
                  type="piis"
                  :fetch-hidden-data="getBankAccountNumber"
                  :disabled="!canEditBankInfo"
                />
                <lf-password
                  placeholder="Routing Number"
                  :value="piis.routingNumber"
                  name="routing_number"
                  type="piis"
                  :fetch-hidden-data="getRoutingNumber"
                  :disabled="!canEditBankInfo"
                />
              </div>
              <div
                class="flex justify-between items-center w-full h-min pl-6 pr-4 bg-disabled rounded"
              >
                <div class="flex justify-start items-center space-x-5 py-5">
                  <icon-base icon="plaid" width="24" height="24" />
                  <div class="flex flex-col">
                    <span class="text-gray-600 font-medium">
                      {{ $t("ORGANIZATION.COMMISSIONS.PLAID_BANK_CONNECTION") }}
                    </span>
                    <span>
                      {{ $t("ORGANIZATION.COMMISSIONS.PLAID_CONNECTION_DESC") }}
                    </span>
                  </div>
                </div>
                <outline-button
                  class="ml-10 lg:ml-38"
                  :disabled="
                    plaidConnected || isFundingAdvisor || isClientFundingAdvisor
                  "
                  @click="connectUsingPlaid"
                >
                  {{
                    plaidConnected
                      ? $t("ORGANIZATION.COMMISSIONS.CONNECTED_PLAID")
                      : $t("ORGANIZATION.COMMISSIONS.CONNECT_PLAID")
                  }}
                </outline-button>
              </div>
            </div>
            <access-control :roles="NOT_FA_ROLE_GROUP">
              <div class="flex justify-end border-t pt-6 mt-6">
                <primary-button type="submit" :disabled="isSubmitting">
                  {{ $t("COMMON.SAVE_CHANGES") }}
                </primary-button>
              </div>
            </access-control>
          </div>
        </form>
      </template>
    </lf-card>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, reactive, onMounted } from "vue";
import { useForm } from "vee-validate";
import { useStore } from "vuex";
import { dispatchAction } from "@/helpers/vee-validate";
import { loadScript, storePlaidToken } from "@/helpers/plaid";
import { useAuth } from "@/hooks/auth";
import { NOT_FA_ROLE_GROUP } from "@/helpers/constants";
import { downloadFile } from "@/helpers/files";
import type { IFile } from "@/models/common";

import LfPassword from "@/components/ui/inputs/LfPassword.vue";
import LfFileUpload from "@/components/ui/inputs/LfFileUpload.vue";
import type { IClient } from "@/models/clients";

const store = useStore();
const file = ref<File | null>(null);
const {
  isUnderwriter,
  isAdmin,
  isSuperAdmin,
  isFundingAdvisor,
  isClientFundingAdvisor
} = useAuth();

onMounted(async () => {
  if (!(isFundingAdvisor || isClientFundingAdvisor)) {
    await store.dispatch("clients/getPlaidLinkToken");
  }
});

const activeClient = computed<IClient>(() => {
  return store.getters["clients/active"];
});
const routingNumberId = computed(
  () => activeClient.value.piis?.find((pii) => pii.key === "RN")?.id
);
const accountNumberId = computed(
  () => activeClient.value.piis?.find((pii) => pii.key === "AN")?.id
);

const getRoutingNumber = async () => {
  await store.dispatch("clients/getRoutingNumber", {
    clientId: activeClient.value.id,
    piiId: routingNumberId.value
  });
};

const canEditBankInfo = computed(() => isAdmin || isSuperAdmin);

const getBankAccountNumber = async () => {
  await store.dispatch("clients/getBankAccountNumber", {
    clientId: activeClient.value.id,
    piiId: accountNumberId.value
  });
};

const revenueShare = computed(
  () => activeClient.value?.revenue_share as number
);

const formW9 = computed(() => {
  const w9Files = activeClient.value?.files?.filter(
    (file: IFile) => (file.file_type = "w9")
  );
  return w9Files && w9Files.length ? w9Files[0] : undefined;
});

const plaidLink = computed(() => store.getters["clients/plaidLinkToken"]);

const piis = reactive({
  accountNumber: computed(
    () => (store.getters["clients/accountNumber"] as string) ?? ""
  ),
  routingNumber: computed(
    () => (store.getters["clients/routingNumber"] as string) ?? ""
  )
});

const { handleSubmit, isSubmitting } = useForm({
  initialValues: {
    revenue_share: revenueShare.value,
    account_number: piis.accountNumber,
    routing_number: piis.routingNumber
  }
});

const isRevenueSharedChanged = (newRevenueShare: number) => {
  return revenueShare.value != newRevenueShare;
};

const isPiisChanged = (values: {
  account_number: string;
  routing_number: string;
}) => {
  return (
    piis.accountNumber != values.account_number ||
    piis.routingNumber != values.routing_number
  );
};

const onSubmit = handleSubmit(async (values, actions) => {
  if (isRevenueSharedChanged(values.revenue_share)) {
    try {
      await dispatchAction(
        { data: { revenue_share: values.revenue_share } },
        actions,
        "clients/updateClient"
      );
    } catch {
      return;
    }
  }

  if (isPiisChanged(values)) {
    try {
      await dispatchAction(values, actions, "clients/updatePiis");
    } catch {
      return;
    }
  }
});

const connectUsingPlaid = async () => {
  await loadScript();
  storePlaidToken(plaidLink.value);
};

const plaidConnected = computed(
  () =>
    store.getters["clients/active"].is_plaid_connected ||
    store.getters["clients/plaidPublicToken"]
);

const uploadFile = async (event: { target: HTMLInputElement }) => {
  file.value = event.target?.files?.[0] || null;
  if (!file.value) {
    return;
  }
  await store.dispatch("clients/uploadFile", { data: file.value, type: "w9" });
};

const canChangeRevenue = computed(
  () => isUnderwriter || isSuperAdmin || isAdmin
);

const handleW9Download = async () => {
  if (formW9.value?.original_name) {
    await downloadFile(formW9.value.file_url, {}, formW9.value.original_name);
  }
};
</script>
