import type { IFile } from "@/models/common";
import type { FileTypeCollection } from "@/models/options";
import { axiosClient } from "@/services/client";
import store from "@/store";
import type { PDF_SERVICES_NAMES } from "@/helpers/constants";
import i18n from "@/i18n";

export const downloadFile = async (
  fileUrl: string,
  params: Record<string, unknown>,
  fileName = "unknown",
  appendDownloadSuffix = true,
  errorMessage = i18n.global.t("COMMON.DOWNLOAD_ERROR_FILE")
): Promise<void> => {
  store.commit("setGlobalLoader", true, { root: true });
  try {
    const response = await axiosClient.get(
      `${fileUrl}${appendDownloadSuffix ? "/download" : ""}`,
      {
        params,
        responseType: "blob"
      }
    );
    createDownloadLink(response.data, fileName).click();
  } catch (error) {
    store.commit("setGlobalMessage", {
      title: errorMessage,
      type: "error"
    });
  } finally {
    store.commit("setGlobalLoader", false, { root: true });
  }
};

export const createDownloadLink = (
  blob: Blob,
  filename = "deal_data"
): HTMLAnchorElement => {
  const url = window.URL.createObjectURL(blob);
  const exportLink = document.createElement("a");
  exportLink.href = url;
  exportLink.setAttribute("download", filename);
  return exportLink;
};

export const getExtensionFromName = (originalName: string): string =>
  originalName.split(".").slice(-1)[0].toLowerCase();

export const areFilesExtensionsValid = (
  files: File[],
  extensions: string[]
): boolean =>
  !files.some((file) => !extensions.includes(getExtensionFromName(file.name)));

export const sortFilesByCategory = (
  filesArr: IFile[],
  fileTypes: Record<string, FileTypeCollection>
): Record<string, IFile[]> => {
  return filesArr.reduce((acc, curr) => {
    const typeGroup = Object.values(fileTypes).find((type) =>
      type.types.includes(curr.file_type)
    );

    return {
      ...acc,
      [typeGroup?.name || ""]: acc[typeGroup?.name as keyof typeof acc]
        ? [...acc[(typeGroup?.name || "") as keyof typeof acc], curr]
        : [curr]
    };
  }, {});
};

export const downloadObjectAsJson = <T>(obj: T, fileName: string): void => {
  const encodedPath = encodeURIComponent(JSON.stringify(obj));
  const data = `data:text/json;charset=utf-8,${encodedPath}`;
  const link = document.createElement("a");
  link.setAttribute("href", data);
  link.setAttribute("download", fileName + ".json");
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const getServiceFileUrlByName = (
  serviceName: (typeof PDF_SERVICES_NAMES)[keyof typeof PDF_SERVICES_NAMES],
  files: IFile[]
): string | null => {
  return (
    files.filter(({ original_name }) => original_name.includes(serviceName))[0]
      ?.file_url || null
  );
};
